import * as R from 'ramda'
import CmsPage from '../cms-page'
import { LandingPageAnalytics } from './analytics'
import { useEffect } from 'react'

const amplitude = process.browser ? require('amplitude-js') : null

const STICKY_CTA_SECTION_EXPERIMENT = {
  fields: {
    component: 'StickyCTAExperiment',
  },
}

const CHAT_BUTTON_EXPERIMENT = {
  fields: {
    component: 'ChatButtonExperiment',
  },
}

const COMPONENTS_FOLLOWED_BY_FOOTER_EXPERIMENT = new Set(['FooterExperiment'])

const Homepage = ({ survey, surveyId, query, fields, slug, isMobile }) => {
  const { title, sections: cmsSections, options: landingPageOptions } = fields
  const { ref } = query

  const getSections = () => {
    return R.reduce(
      (allSections, section) => {
        const component = section.fields.component

        if (component !== 'SignUp') {
          allSections.push(section)
        }

        if (COMPONENTS_FOLLOWED_BY_FOOTER_EXPERIMENT.has(component)) {
          allSections.push(STICKY_CTA_SECTION_EXPERIMENT)
          allSections.push(CHAT_BUTTON_EXPERIMENT)
        }

        return allSections
      },
      [],
      cmsSections
    )
  }

  const sections = getSections()

  useEffect(() => {
    LandingPageAnalytics.onView({
      title,
      slug,
      ref,
    })
  }, [title, slug, ref])

  return (
    <CmsPage
      survey={survey}
      surveyId={surveyId}
      query={query}
      fields={{ ...fields, sections }}
      source={ref}
      isMobile={isMobile}
      data-testid="home-page"
      landingPageOptions={landingPageOptions}
    />
  )
}

export default Homepage
